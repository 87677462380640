<template>
    <div class="mt-3 stage-inner-container-padded">
        <b-row>
            <b-col sm="6" md="5" lg="3">
                <h3>Batch Manager</h3>
            </b-col>
            <b-col sm="6" md="5" lg="3">
                <b-form-checkbox v-on:change="toggleAutoUpdate" name="auto-update" switch :checked="autoUpdate">
                    Auto Refresh <b>(Checked: {{ autoUpdate }})</b>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row v-for="(out, idx) in outstanding" :key="idx" class="mb-1">
            <b-col cols="12">
                <b-card :header="`${out.name} (${out.status})`" :title="``" :bg-variant="out.headerVariant" text-variant="white">
                    <b-row>
                        <b-col>
                            <strong>Expecting Total Letters:</strong> {{out.expected_total_count}}
                        </b-col>
                        <b-col v-if="out.number_queued != 0">
                            <strong>Queued:</strong> {{out.number_queued}}
                        </b-col>
                        <b-col>
                            <strong>Generated:</strong> {{out.number_generated}}
                        </b-col>
                        <b-col>
                            <strong>Failed:</strong> {{out.number_failed}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button v-if="out.last_batch_download_by == null" :disabled="!(out.position_in_processing_queue == 0)" @click="openNameModal(out)" variant="primary">Download Batch</b-button>
                            <b-button v-else :disabled="!(out.position_in_processing_queue == 0)" @click="openNameModal(out)" variant="secondary">Last Batch Download By {{out.last_batch_download_by}} on {{locButils.formatters.timestampToDateTime(out.last_batch_download_on, true, false)}}</b-button>
                        </b-col>
                        <b-col>
                            <b-button v-if="out.sent_by == null" :disabled="!(out.position_in_processing_queue == 0) || out.sent" @click="markBatchSent(out)" variant="primary">Mark Batch as Sent</b-button>
                            <b-button v-else :disabled="!(out.position_in_processing_queue == 0) || out.sent" variant="secondary">Batch Sent By {{out.sent_by}} on {{locButils.formatters.timestampToDateTime(out.sent_on, true, false)}}</b-button>
                        </b-col>
                        <b-col>
                            <b-button v-if="out.sent_by != null" :disabled="!(out.position_in_processing_queue == 0)" @click="dismissBatch(out)" variant="danger" class='material-icons'>close</b-button>
                            <b-button v-else @click="dismissBatch(out)" variant="danger">Dismiss Batch</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-modal v-model="showNamingModal" hide-footer title="Any Last Minute Instructions?" size="lg" header-bg-variant="info">
            <b-row v-if="nameModal.bePatient != true">
                <b-col>
                    <h4>Did You Want The File Name Of The Letters In The Zip Folder To Be?</h4>
                </b-col>
            </b-row>
            <b-row v-if="nameModal.bePatient != true">
                <b-col>
                    If you don't enter anything here, the computer will give a filename to each letter... but computers aren't very creative so each letter will have a name like '8023493b-db89-43c3-8e68-3c6ea02f41d0.pdf'
                </b-col>
            </b-row>
            <b-row class="mt-2" v-if="nameModal.bePatient != true">
                <b-col>
                    <b-form-group
                        id="name-modal-name-group"
                        placeholder=""
                        label="Letter Filename"
                        label-for="name-value-name"
                    >
                        <b-form-input id="name-value-name" v-model="nameModal.batchName" trim></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="nameModal.bePatient == true">
                <b-col>
                    <h5>Please be patient, the process of packing all those pdfs into a zip file can take a minute or two.</h5>
                </b-col>
            </b-row>
            <b-button v-if="nameModal.bePatient != true" class="mt-3" block variant="success" @click="fetchAllLetters">Lets Go</b-button>
            <b-button v-if="nameModal.bePatient == true" class="mt-3" block variant="secondary" :disabled="true">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </b-button>
            <b-button class="mt-3" block @click="hideNameModal">Close</b-button>
        </b-modal>
    </div>
</template>

<script>
const _ = require('underscore');
const butils = require('../../libs/basicUtils.js'); 
var fileDownload = require('js-file-download');

export default {
    name: 'batchmanager',
    components:{

    },
    props:{

    },
    data(){
        return{
            locButils: butils,
            autoUpdate: true,
            autoUpdateInterval: null,
            outstanding: [],
            baseFileURL: process.env.VUE_APP_FILE_API_BASE_URL,
            baseAPIURL: process.env.VUE_APP_API_BASE_URL,
            showNamingModal: false,
            nameModal: {
                batchID: null,
                batch: null,
                batchName: null,
                bePatient: false
            }
        }
    },
    methods:{
        updateData(){
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + "/letter/batch/outstanding")
            .then((letterResp)=>{
                console.log(letterResp.data.result);
                this.outstanding = [];
                letterResp.data.result.forEach((batch)=>{
                    var status = '';
                    var headerVariant = "dark";
                    if(batch.position_in_processing_queue == 0){
                        status = status + 'Generation Completed';
                        headerVariant = "success";
                    }else if(batch.position_in_processing_queue == 1){
                        status = status + 'Processing Now';
                        headerVariant = "primary";
                    }else{
                        status = status + `Awaiting Processing, Position ${batch.position_in_processing_queue - 1}`;
                        headerVariant = "info";
                    }

                    this.outstanding.push({ ...batch, status, headerVariant });
                })
            })
            .catch((err)=>{
                console.log(err);
                butils.createToast(this, `Failed While Fetching Outstanding Letter Batches`,`Details in console`, 'danger', 5)
            })
        },
        toggleAutoUpdate(){
            if(this.autoUpdate){
                this.autoUpdate = false;
            }else{
                this.autoUpdate = true;
            }

            if(this.autoUpdate){
                this.updateData();
                this.autoUpdateInterval = setInterval(()=>{
                    this.updateData();
                }, 3000)
            }else{
                clearInterval(this.autoUpdateInterval);
            }
        },
        openNameModal(batch){
            this.nameModal = {
                batchID: batch.id,
                batch: batch,
                batchName: null,
                bePatient: false,
            };
            this.showNamingModal = true;
        },
        hideNameModal(){
            this.showNamingModal = false;
            this.nameModal = {
                batchID: null,
                batch: null,
                batchName: null,
                bePatient: false,
            };
        },
        fetchAllLetters(){
            this.nameModal.bePatient = true;
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/letter/batch/files/${this.nameModal.batchID}`)
            .then((letterResp)=>{
                console.log(letterResp.data.result);
                var packed = {
                    fileNameBase: null,
                    files: letterResp.data.result.fileIDs
                };
                if(this.nameModal.batchName != null && this.nameModal.batchName != '' && this.nameModal.batchName.length > 0){
                    packed.fileNameBase = this.nameModal.batchName;
                }
                butils.customInstance.timeoutAndBlob(300000).post(process.env.VUE_APP_FILE_API_BASE_URL + `/batchpdf`, packed)
                .then((zipResp)=>{
                    console.log(zipResp)
                    fileDownload(zipResp.data, `${this.nameModal.batch.name}.pdf`);
                    butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/letter/batch/mark/downloaded/${this.nameModal.batchID}`)
                    .then((markDownloadResponse)=>{
                        var matches = this.outstanding.filter((out)=>{ return out.id == this.nameModal.batchID});
                        matches[0].last_batch_download_by = 'You'
                        matches[0].last_batch_download_on = Date.now();
                        this.hideNameModal();
                    })
                    .catch((err)=>{
                        console.log(err);
                        butils.createToast(this, `Failed While Marking Letter Batch Downloaded`, `Details in console`, `danger`, 100);
                        this.hideNameModal()
                    })
                })
                .catch((err)=>{
                    console.log(err);
                    butils.createToast(this, `Failed While Fetching Zip File`, `Details in console`, `danger`, 100);
                    this.hideNameModal()
                })
                // 
            })
            .catch((err)=>{
                console.log(err);
                butils.createToast(this, `Failed While Fetching Outstanding Letter Batches`,`Details in console`, 'danger', 5)
                this.hideNameModal()
            })
        },
        markBatchSent(batch){
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/letter/batch/mark/sent/${batch.id}`)
            .then((letterResp)=>{
                this.updateData();
                console.log(letterResp.data);
                butils.createToast(this, `Batch Marked as Sent`, `Batch has been marked as sent`, 'success', 5)                
            })
            .catch((err)=>{
                console.log(err);
                butils.createToast(this, `Failed While Marking Batch Sent`,`Details in console`, 'danger', 5)
            })
        },
        dismissBatch(batch){
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/letter/batch/dismiss/${batch.id}`)
            .then((letterResp)=>{
                console.log(letterResp.data);
                this.outstanding = this.outstanding.filter((out)=>{ return out.id != batch.id });
            })
            .catch((err)=>{
                console.log(err);
                butils.createToast(this, `Failed While Dismissing Batch`,`Details in console`, 'danger', 5)
            })
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        this.updateData();
        if(this.autoUpdate){
            this.autoUpdateInterval = setInterval(()=>{
                this.updateData();
            }, 2000)
        }
    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){
        // Attempt to clear that interval regardless of if it exists or not
        clearInterval(this.autoUpdateInterval);
    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>